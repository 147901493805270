import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const Gallery = ({ projectList }) => {
    const [show, setShow] = useState(false);
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [leftButtonAnimation, setLeftButtonAnimation] = useState('');
    const [rightButtonAnimation, setRightButtonAnimation] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setCurrentProjectIndex(index);
        setShow(true);
    };

    const handleNext = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projectList.length);
        triggerAnimation('right');
    };

    const handlePrev = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex === 0 ? projectList.length - 1 : prevIndex - 1));
        triggerAnimation('left');
    };

    const triggerAnimation = (direction) => {
        if (direction === 'left') {
            setLeftButtonAnimation('wobble-left');
            setTimeout(() => setLeftButtonAnimation(''), 500); // Reset animation class after 500ms
        } else {
            setRightButtonAnimation('wobble-right');
            setTimeout(() => setRightButtonAnimation(''), 500); // Reset animation class after 500ms
        }
    };

    return (
        <>
            {projectList.map((project, index) => (
                <img
                    key={index}
                    src={project.image}
                    alt={project.projectName}
                    onClick={() => handleShow(index)}
                    className='collection-images'
                />
            ))}

            <Modal className='modal' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{projectList[currentProjectIndex].projectName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <a href={projectList[currentProjectIndex].imageHref} target="_blank" rel="noopener noreferrer">
        <img className='modal-image' src={projectList[currentProjectIndex].image} alt={projectList[currentProjectIndex].projectName} />
    </a>  </Modal.Body>
             <Modal.Footer>
                    <Button className={`left-button ${leftButtonAnimation}`} variant="secondary" onClick={handlePrev}>
                        Previous
                    </Button>
                    <Button className={`right-button ${rightButtonAnimation}`} variant="secondary" onClick={handleNext}>
                        Next
                    </Button>
              </Modal.Footer>
            </Modal>
        </>
    );
};

export default Gallery;
