import projectOne from '../assets/images/projectOne.webp';
import projectTwo from '../assets/images/projectTwo.webp';
import projectThree from '../assets/images/projectThree.webp';
import projectFour from '../assets/images/projectFour.webp';
import projectFive from '../assets/images/projectFive.webp';
import projectSix from '../assets/images/projectSix.webp';


export const projectList = [
   
    {
        image: projectSix,
        projectName: "Mirrorrose.com Photography",
        projectLocation: "Reykjavik, Iceland",
        imageHref:"https://www.mirrorrose.com"
    } ,
    {
        image: projectFour,
        projectName: "Raftvistur.is - High Voltage Services",
        projectLocation: "Reykjavik, Iceland"
        ,
        imageHref:"https://www.raftvistur.is/"
        
    },
    {
        image: projectThree,
        projectName: "Linkleberry.com - Revolutionary Link-In-Bio Tool",
        projectLocation: "Kopavagur, Iceland"
        ,
        imageHref:"https://www.linkleberry.com/"
    },
    {
        image: projectFive,
        projectName: "SkyFaze.com - Next Level ThreeJs Website",
        projectLocation: "Reykjavik, Iceland"
        ,
        imageHref:"https://www.skyfaze.com/"
    },
    {
        image: projectTwo,
        projectName: "Lagnabraedur.is - Plumbing Services in Iceland",
        projectLocation: "Kopavagur, Iceland"
        ,
        imageHref:"https://www.lagnabraedur.is"
    },
    {
        image: projectOne,
        projectName: "Westranga.com Fly-Fishing Experienced Guides",
        projectLocation: "Reykjavik, Iceland"
        ,
        imageHref:"https://www.westranga.com/"
    }
]