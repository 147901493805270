import React, { useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import ProjectCard from '../../../Components/ProjectCard/ProjectCard';
import { projectList } from '../../../Data/projectData';
import { scrollTo } from '../../../tools/scroll';
import Gallery from '../../../Components/Gallery'; // Import the Gallery component

const Projects = () => {
    useEffect(() => {
        scrollTo(10, 10);
    }, []);

    return (
        <section className="mt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-5"> <h2 className=" text-center"><Badge className='badges pl-4 pr-4 p-2' variant="danger" pill>Projects</Badge></h2> </div>
                    <div className="col-12 mb-5">
                        <h1 className="text-center mt-3">Some of<br /> Our Latest Projects</h1>
                    </div>
                  <Gallery projectList={projectList}>
                    {projectList.map(each => <ProjectCard key={each.projectName} projectInfo={each} />)}
</Gallery>
                  
                </div>
               
            </div>
        </section>
    );
};

export default Projects;
