import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card } from "react-bootstrap";
import { manageService } from "../../../Services/DashboardServices";

const ManageServiceCard = ({ serviceInfo, onDeleteService }) => {
  const { serviceName, serviceImg, serviceDesc, servicePrice, id } =
    serviceInfo; // Include additional fields

  const handleDelete = async () => {
    try {
      await manageService(id); // Assume this deletes the service
      onDeleteService(serviceInfo.id); // This should now correctly call the passed function
    } catch (error) {
      console.error("Failed to delete service:", error);
    }
  };

  return (
    <div className="col-md-4 d-flex justify-content-center mb-5">
      <Card
        border="light"
        className="shadow flex-column p-3 align-items-center"
        style={{ width: "18rem" }}
      >
        <img
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
          src={serviceImg}
          alt={serviceName}
        />
        <h5 className="text-center text-danger mt-3">{serviceName}</h5>
        <p className="text-center">{serviceDesc}</p>
        <h6 className="text-center">${servicePrice}</h6>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          onClick={handleDelete}
          color="red"
          title="Remove"
          icon={faTrash}
        />
      </Card>
    </div>
  );
};

export default ManageServiceCard;
