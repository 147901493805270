import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import topBannerImg from "../../../assets/images/topBanner.webp";

const TopBanner = () => {
  return (
    <section className="mt-5">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-6">
            <div className="container">
              <h1 className="text-dangen" >
                Top-Tier React Template
                <br />
                Powered by Skyfaze
              </h1>
              <p className="mt-5">
                {" "}
                <span>
                  {" "}
                  <FontAwesomeIcon className="mr-1" color="green" icon={faCheck} /> Exclusive
                </span>{" "}
                <span className="ml-md-4 d-md-inline d-block">
                  {" "}
                  <FontAwesomeIcon className="mr-2" color="green" icon={faCheck} />
                  Premium Website
                </span>{" "}
                <span className="ml-md-4 d-md-inline d-block">
                  {" "}
                  <FontAwesomeIcon className="mr-2" color="green" icon={faCheck} />
                  100% Performance
                </span>{" "}
              </p>
              <p className="mt-4">
                Elevate your digital presence with our React-based website template without recurring fees or hosting fees. 
                Tailored for seamless performance and designed to
                impress, Skyfaze offers a unique opportunity to own a high-end
                website at a fraction of the usual cost. With our service, you
                benefit from a Custom React Development Service, Free Hosting Forever and
                dynamic updates powered by Firebase. Perfect for businesses aiming for
                excellence without the high expenses.
              </p>
              <Link to="/services" className="btn btn-danger mt-4">
                Get Started
              </Link>
            </div>
          </div>
          <div className="col-md-6 mt-md-0 mt-5">
            <div className="text-center">
              <img
                className="HeaderImage"
                style={{ height: "600px" }}
                src={topBannerImg}
                alt="Exclusive React Development Offer"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
