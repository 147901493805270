import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Footer from '../../../Components/Shared/Footer/Footer';
import TopBar from '../../../Components/Shared/TopBar/TopBar';
import About from '../About/About';
import Contact from '../Contact/Contact';
import Services from '../PaintingServices/PaintingServices';
import Projects from '../Projects/Projects';
import TopBanner from '../TopBanner/TopBanner';


const sectionVariants = {
    left: {
        hidden: { x: -50},
        visible: { x: 0, opacity: 1, transition: { type: 'spring', stiffness: 100, damping: 10 } },
    },
    right: {
        hidden: { x: 50},
        visible: { x: 0, opacity: 1, transition: { type: 'spring', stiffness: 100, damping: 10 } },
    },
};

const ScrollSection = ({ children, direction = 'left' }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={sectionVariants[direction]}>
            {children}
        </motion.div>
    );
};

const Home = () => {
    return (
        <div>
            <ScrollSection direction="right"><TopBar /></ScrollSection>
            <TopBanner />
            <ScrollSection direction="left"><About /></ScrollSection>
            <ScrollSection direction="right"><Services /></ScrollSection>
            <ScrollSection direction="left"><Projects /></ScrollSection>
            <ScrollSection direction="left"><Contact /></ScrollSection>
            <Footer />
        </div>
    );
};

export default Home;
