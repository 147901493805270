
const firebaseConfig = {
  apiKey: "AIzaSyBJxK_3q5zsAsjb9byOUXfRhYdVKCN6c90",
  authDomain: "painting-website-fd4cc.firebaseapp.com",
  projectId: "painting-website-fd4cc",
  storageBucket: "painting-website-fd4cc.appspot.com",
  messagingSenderId: "326057202173",
  appId: "1:326057202173:web:9c50668e485d0204ef4994",
  measurementId: "G-GNTTT4ETEJ"
};
  
  export default firebaseConfig;
  

