
import { Badge } from 'react-bootstrap';

const Terms = () => {
    return (
        <section className="my-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-5">
                        <h3 className="text-center"><Badge className='badges pl-4 pr-4 p-2 ' variant="danger" pill>Terms and Conditions</Badge></h3>
                    </div>
                    <div className="col-12">
                        <p className="mt-4">
                            Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern [Your Company's] relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.
                        </p>
                        <p className="mt-4">
                            The term '[Your Company]' or 'us' or 'we' refers to the owner of the website whose registered office is [address]. Our company registration number is [registration number and place of registration]. The term 'you' refers to the user or viewer of our website.
                        </p>
                        <h5>Use of the Website</h5>
                        <p className="mt-4">
                            This website uses cookies to monitor browsing preferences. If you allow cookies to be used, personal information may be stored by us for use by third parties.
                        </p>
                        <p className="mt-4">
                            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
                        </p>
                        <h5>License and Copyright</h5>
                        <p className="mt-4">
                            All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.
                        </p>
                        <p className="mt-4">
                            Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.
                        </p>
                        <h5>Links to Other Websites</h5>
                        <p className="mt-4">
                            Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
                        </p>
                        <h5>Website Usage</h5>
                        <p className="mt-4">
                            Your use of this website and any dispute arising out of such use of the website is subject to the laws of [Your Country].
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Terms;
