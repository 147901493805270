export const skillInfo = [
    {
        perchant: 100,
        name: 'High Performance',
        pathColor: '#ABCF26', // Green for performance
        desc: 'Engineered for speed and efficiency, our template ensure your website loads quickly and runs smoothly, enhancing both user satisfaction and SEO rankings.'
    },
    {
        perchant: 100,
        name: 'Customization Ease',
        pathColor: '#F91D0E', // Red for flexibility and customization
        desc: 'Our template are designed with flexibility in mind, allowing you to tailor the look and feel of your website with ease, ensuring it perfectly matches your brand identity.'
    },
    {
        perchant: 100,
        name: 'Firebase Integration',
        pathColor: '#FC9005', // Orange for Firebase's vibrant ecosystem
        desc: 'Leverage the power of Firebase for real-time data storage, authentication, and more. Our template integrate seamlessly with Firebase, enabling dynamic content updates and user management.'
    },
    {
        perchant: 100,
        name: 'User Experience Design',
        pathColor: '#128DC5', // Blue for the depth of design
        desc: 'Crafted with user experience at the forefront, our template provide intuitive navigation and engaging interfaces that keep your visitors coming back for more.'
    }
];
