import React from 'react';
import { Badge } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'; // Ensure SweetAlert2 is correctly imported
import CustomSvg from '../../../Components/CustomSvg'
const Contact = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        emailjs.send('service_elwic2b', 'template_rokcwz7', data, 'h5rhdyXeQC2U3sN9v')
            .then((result) => {
                
                Swal.fire({
                    title: 'Success!',
                    text: 'Message sent successfully!',
                    icon: 'success',
                    toast: true,
                    position: 'top-end', 
                    showConfirmButton: false, 
                    timer: 3000, 
                    width: '400px', 
                    padding: '3em', 
                    background: '#fff url(/images/trees.png)', 
                });
            }, (error) => {
               
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to send message, please try again later.',
                    icon: 'error',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false, 
                    timer: 3000, 
                    width: '400px', 
                    padding: '3em', 
                });
            });
    };
   


    return (
        <section className="mb-5 pb-5" >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 mt-5" > <h3 className="text-center" ><Badge className='badges pl-4 pr-4 p-2 ' variant="danger" pill >Contact</Badge></h3> </div>
                    <div className="col-12 mb-5" >
                        <h1 className="text-center mt-3" >Let us handle your <br /> project, professionally.</h1>
                    </div>

                    <div className="col-md-6">
                        <CustomSvg/>
                    </div>
                    <div className="col-md-6">

                        <div className="col-md-12">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input className="form-control mb-4 border-0 bg-light" placeholder="First Name" {...register("firstName")} />
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control mb-4 border-0 bg-light" placeholder="Last Name" {...register("lastName")} />
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-md-6">
                                        <input 
                                            className={`form-control mb-4 border-0 bg-light ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Email Address" 
                                            {...register("email", {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                    message: 'Invalid email address'
                                                }
                                            })} 
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control mb-4 border-0 bg-light" placeholder="Phone Number" {...register("phoneNumber")} />
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-md-12">
                                        <textarea 
                                            rows="5" 
                                            style={{ resize: 'none' }} 
                                            className={`form-control mb-4 border-0 bg-light ${errors.message ? 'is-invalid' : ''}`}
                                            placeholder="Your Message" 
                                            {...register("message", { required: 'Message is required' })} 
                                        />
                                        {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <input className="btn btn-danger" type="submit" value="Send Message" />
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;