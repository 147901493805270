import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container); // Create a root.


root.render(

    <App />
 
);

reportWebVitals();
