import firebase from "firebase/app";
import "firebase/firestore";
import firebaseConfig from './../firebase.config';

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}
// Assuming firebase is already initialized elsewhere in your project
const db = firebase.firestore();

export const getSkyFazeServices = async () => {
    try {
        const snapshot = await db.collection('services').get();
        const services = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return services;
    } catch (error) {
        console.error("Error fetching services:", error);
        throw error; // Re-throw the error to handle it in the caller
    }
}
export const getAdminReviews = async () => {
    try {
        const snapshot = await db.collection('reviews').get();
        const reviews = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return reviews;
    } catch (error) {
        console.error("Error fetching reviews for admin:", error);
        throw error;
    }
};

export const approveReview = async (id) => {
    try {
        await db.collection('reviews').doc(id).update({ isApproved: true });
        console.log("Review approved successfully");
        return { success: true, message: "Review approved successfully" };
    } catch (error) {
        console.error("Error approving review:", error);
        return { success: false, message: error.message };
    }
};
export const getReviews = async () => {
    try {
   
        const snapshot = await db.collection('reviews').where('isApproved', '==', true).get();
        const reviews = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return reviews;
    } catch (error) {
        console.error("Error fetching approved reviews:", error);
        throw error; 
    }
}