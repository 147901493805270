import React from 'react';
import styles from '../../../Styles/loader.module.css';

const Loader = () => {
    return (
        <div className={styles.reverseSpinner} >

        </div>
    );
};

export default Loader;