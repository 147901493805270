import firebase from "firebase/app";
import "firebase/auth";
import { createContext, useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";
import './App.css';
import Footer from "./Components/Shared/Footer/Footer";
import TopBar from "./Components/Shared/TopBar/TopBar";
import AddService from "./Pages/Dashboard/AddService/AddService";
import Book from "./Pages/Dashboard/Book/Book";
import BookingList from "./Pages/Dashboard/BookingList/BookingList";
import MakeAdmin from "./Pages/Dashboard/MakeAdmin/MakeAdmin";
import MangeServices from "./Pages/Dashboard/MangeServices/MangeServices";
import OrderList from "./Pages/Dashboard/OrderList/OrderList";
import Review from "./Pages/Dashboard/Review/Review";
import About from "./Pages/Home/About/About";
import Terms from "./Pages/Home/terms/Terms";
import Conditions from "./Pages/Home/privacy/Privacy";
import Home from "./Pages/Home/Home/Home";
import Contact from "./Pages/Home/Contact/Contact";
import Services from "./Pages/Home/PaintingServices/PaintingServices";
import Projects from "./Pages/Home/Projects/Projects";
import Login from "./Pages/Login/Login";
import PrivateRoute from "./Pages/Login/PrivateRoute";

export const UserContext = createContext();
export const SideBarContext = createContext();

function App() {
  const [loggedInUser, setLoggedInUser] = useState({});
  const [sideBarInfo, setSideBarInfo] = useState({});
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in or token was refreshed.
        const { displayName, email, photoURL } = user;
        setLoggedInUser({
          isSignedIn: true,
          name: displayName,
          email: email,
          photoURL: photoURL
        });
      } else {
        // User is signed out.
        setLoggedInUser({});
      }
    });

    return () => unsubscribe(); // Make sure we unbind the listener when the component unmounts
  }, []);
  return (
    <UserContext.Provider value={[loggedInUser, setLoggedInUser]} >
      <Router>
        <Toaster />
        <Switch>
          <Route exact path="/" >
            <Home></Home>
          </Route>
          <Route path="/login" >
            <TopBar></TopBar>
            <Login></Login>
            <Footer></Footer>
          </Route>
          <Route path="/about" >
            <TopBar></TopBar>
            <About></About>       
            <Footer></Footer>
          </Route>
          <Route path="/terms" >
            <TopBar></TopBar>
            <Terms></Terms>
            <Footer></Footer>
          </Route>
      
          <Route path="/privacy" >
            <TopBar></TopBar>
            <Conditions></Conditions>
            <Footer></Footer>
          </Route>
          <Route path="/services" >
            <TopBar></TopBar>
            <Services></Services>
            <Footer></Footer>
          </Route>
          <Route path="/projects" >
            <TopBar></TopBar>
            <Projects></Projects>
            <Footer></Footer>
          </Route>
          <Route path="/contact" >
            <TopBar></TopBar>
            <Contact></Contact>
            <Footer></Footer>
          </Route>
          <SideBarContext.Provider value={[sideBarInfo, setSideBarInfo]} >
            <PrivateRoute path="/dashboard/book/:id" >
              <Book></Book>
            </PrivateRoute>
             <Route path="/dashboard/bookingList" >
            
            <BookingList></BookingList>
            
          </Route>
            <PrivateRoute path="/dashboard/review" >
              <Review></Review>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/addService" >
              <AddService></AddService>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/manageServices" >
              <MangeServices></MangeServices>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/makeAdmin" >
              <MakeAdmin></MakeAdmin>
            </PrivateRoute>
            <PrivateRoute path="/dashboard/orderList" >
              <OrderList></OrderList>
            </PrivateRoute>
          </SideBarContext.Provider>
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
