import React, { useEffect, useState } from 'react';
import Loader from '../../../Components/Shared/Loader/Loader';
import TestimonialCard from '../../../Components/TestimonialCard/TestimonialCard';
import { getReviews } from '../../../Services/UserServices';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonial = () => {
    const [testimonialInfo, setTestimonialInfo] = useState([]);

    useEffect(() => {
        getReviews().then(data => setTestimonialInfo(data));
    }, []);

    return (
        <section className="mt-5">
            <div className=" container">
                <div className=" row justify-content-center">
                  

                    <div className="mb-5">
                        {testimonialInfo.length === 0 ? <Loader /> : ""}
                    </div>

                    <div className="w-100 custom-overflow">
                        {testimonialInfo.length > 3 ? (
                            <Slider dots={true} infinite={true} speed={500} slidesToShow={3} slidesToScroll={1}>
                             {testimonialInfo.map((each, index) => <TestimonialCard key={each._id || index} testimonialInfo={each} />)}

                            </Slider>
                        ) : (
                            <div className="d-flex justify-content-between">
                               {testimonialInfo.map((each, index) => <TestimonialCard key={each._id || index} testimonialInfo={each} />)}

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
