import { faFacebookSquare, faInstagramSquare, faLinkedin, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcMastercard } from '@fortawesome/free-brands-svg-icons'; // Assuming these are the correct imports

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Testimonial from '../../../Pages/Home/Testimonial/Testimonial';

const Footer = () => {
    return (
        <section  > 
        <Testimonial/>
         <div className="bg-danger text-white p-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h5> <FontAwesomeIcon className="mr-1" icon={faMapMarker} /> sky-faze Ehf (kt.0108924567)<br></br> Reykjavik, 101 Iceland</h5>
                    </div>
                    <div className="col-md-2">
                        <h5 className="mb-4 mt-md-0 mt-4">Company</h5>
                        <p><a href="/about" className="text-white">About</a></p>
                        <p><a href="/projects" className="text-white">Latest Project</a></p>
                        <p><a href="/terms" className="text-white">Terms & Condition</a></p>
                        <p><a href="/privacy" className="text-white">Privacy Policy</a></p>
                    </div>
                    <div className="col-md-2">
                        <h5 className="mb-4 mt-md-0 mt-4">Quick Links</h5>
                        <p><a href="/services" className="text-white">Services</a></p>
                        <p><a href="/contact" className="text-white">Contact</a></p>
                        <p><a href="/login" className="text-white">Login</a></p>
                    </div>
                    <div className="col-md-4">
                        <h5 className="mb-4 mt-md-0 mt-4">About us</h5>
                        <p>A new era of web development with a template that's not just a website but a comprehensive digital solution. Before Skyfaze, such advanced and integrated capabilities were meant only for high-budget clients. Now, we're bringing these powerful features within reach for every business, ensuring you can offer top-tier services to your clients without the top-tier price tag.</p>
                        <div>
    <a href="https://www.facebook.com/skyfaze.agency" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
        <FontAwesomeIcon className="ml-3 text-white" size="2x" icon={faFacebookSquare} />
    </a>
    <a href="https://www.instagram.com/bio.linkleberry" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
        <FontAwesomeIcon className="ml-3 text-white" size="2x" icon={faInstagramSquare} />
    </a>
    <a href="https://www.linkedin.com/skyfaze" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
        <FontAwesomeIcon className="ml-3 text-white" size="2x" icon={faLinkedin} />
    </a>
    <a href="https://www.youtube.com/channel/UCwaeEi0-x418NRlHh3fIcKA" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
        <FontAwesomeIcon className="ml-3 text-white" size="2x" icon={faYoutubeSquare} />
    </a>
      {/* Payment icons */}
      <a href="#" aria-label="Visa">
                                <FontAwesomeIcon className="ml-3 text-white" size="2x" icon={faCcVisa} />
                            </a>
                            <a href="#" aria-label="Mastercard">
                                <FontAwesomeIcon className="ml-3 text-white" size="2x" icon={faCcMastercard} />
                            </a>
</div>

                    </div>
                </div>
            </div>
          </div>
        </section>
    );
};

export default Footer;
