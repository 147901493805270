import {
  faCartPlus,
  faHome,
  faList,
  faMailBulk,
  faPlus,
  faTh,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SideBarContext, UserContext } from "../../../App";
import "./Sidebar.css";
import { logout } from "../../../Services/AuthServices";

const Sidebar = () => {
  const [{ isAdmin }] = useContext(UserContext);
  const [sideBarInfo, setSideBarInfo] = useContext(SideBarContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSideBarInfo({ ...sideBarInfo, isOpen });
    // Removed the changeSideBar function from sideBarInfo object since it directly manipulates state
    // and its inclusion wasn't demonstrated as necessary from the provided snippet.
  }, [isOpen, setSideBarInfo, sideBarInfo]);

  const handleLogout = async () => {
    try {
      await logout();
      console.log("User logged out successfully");
      // Assuming your app's state is updated accordingly elsewhere as needed post-logout.
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <div className="sidebar bg-danger position-fixed d-flex flex-column justify-content-between col-md-2 py-5 px-4 h-100">
      {/* Link List */}
      <ul className="list-unstyled">
        <li><Link to="/" className="text-white"><FontAwesomeIcon icon={faHome} /> <span>Home</span></Link></li>
        {!isAdmin ? (
          <>
            <li><Link to="/services" className="text-white"><FontAwesomeIcon icon={faCartPlus} /> <span>Buy Service</span></Link></li>
            <li><Link to="/dashboard/bookingList" className="text-white"><FontAwesomeIcon icon={faList} /> <span>My Purchases</span></Link></li>
            <li><Link to="/dashboard/review" className="text-white"><FontAwesomeIcon icon={faMailBulk} /> <span>Add Review</span></Link></li>
          </>
        ) : (
          <>
            <li><Link to="/dashboard/addService" onClick={() => setIsOpen(!isOpen)} className="text-white"><FontAwesomeIcon icon={faPlus} /> <span>Add New Service</span></Link></li>
            <li><Link to="/dashboard/manageServices" onClick={() => setIsOpen(!isOpen)} className="text-white"><FontAwesomeIcon icon={faTh} /> <span>Services & Reviews</span></Link></li>
            <li><Link to="/dashboard/orderList" onClick={() => setIsOpen(!isOpen)} className="text-white"><FontAwesomeIcon icon={faList} /> <span>Orders</span></Link></li>
            <li><Link to="/dashboard/makeAdmin" onClick={() => setIsOpen(!isOpen)} className="text-white"><FontAwesomeIcon icon={faUserPlus} /> <span>Make Admin</span></Link></li>
          </>
        )}
      </ul>
      {/* Logout Button */}
      <button onClick={handleLogout} style={{
        backgroundColor: '#ef4444',
        color: 'white',
        padding: '8px 16px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '16px',
        marginTop: '20px',
        transition: 'background-color 0.3s'
      }} onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#dc2626'} onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#ef4444'}>
        Log Out
      </button>
    </div>
  );
};

export default Sidebar;
